import styled from 'styled-components';

import NotificationsIcon from '@/assets/icons/notifications.svg';

interface NotificationsBadgeIconProps {
  totalNotifications: number;
}

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const Badge = styled.span`
  position: absolute;
  top: -3px;
  right: -5px;
  min-width: 20px;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: bold;
  color: var(--quo-white);
  text-align: center;
  background-color: var(--notification-badge-color);
  border-radius: 10px;
`;

const NotificationsBadgeIcon = ({ totalNotifications }: NotificationsBadgeIconProps) => (
  <Container>
    <NotificationsIcon />
    {totalNotifications > 0 && (<Badge>{totalNotifications > 99 ? '+99' : totalNotifications}</Badge>)}
  </Container>
);

export default NotificationsBadgeIcon;
