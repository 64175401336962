import styled, { css } from 'styled-components';

// Components
import Tooltip from '@/components/content/tooltip/Tooltip';

const reliabilityBackgrounds = css`
  &.reliability__score--1,
  &.reliability__score--A {
    background: var(--reliability-1);
  }
  &.reliability__score--2,
  &.reliability__score--B {
    background: var(--reliability-2);
  }
  &.reliability__score--3,
  &.reliability__score--C {
    background: var(--reliability-3);
  }
  &.reliability__score--4,
  &.reliability__score--D {
    background: var(--reliability-4);
  }
  &.reliability__score--5,
  &.reliability__score--E {
    background: var(--reliability-5);
  }
  &.reliability__score--6,
  &.reliability__score--F {
    background: var(--reliability-6);
  }
`;

const ReliabilityStyled = styled.span`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  ${reliabilityBackgrounds}
`;

const InformationReliability = ({ value }: { value: string | null }) => {
  if (!value) { return null; }

  const [score, tooltip] = value ? value.split('-') : [];

  return (
    <Tooltip title={tooltip}>
      <ReliabilityStyled className={`reliability__score--${score.trim()}`}>{score}</ReliabilityStyled>
    </Tooltip>
  );
};

export default InformationReliability;
