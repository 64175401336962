import { useMemo } from 'react';
import { useGetNotifications } from '../services/getNotifications';

import { NotificationsType } from '../types';

export const useNotifications = () => {
  const { data, isLoading, refetch } = useGetNotifications();

  const totalUnread = useMemo(() => {
    if (data) {
      return (
        data.intel_finished.unread
        + data.intel_raw.unread
        + data.vulnerabilities.unread
        + data.drp_alerts.unread
        + data.drp_takedowns.unread
        + data.rfi.unread
      );
    }

    return 0;
  }, [data]);

  const totalUnacknowledged = useMemo(() => {
    if (data) {
      return (
        +data.vulnerabilities.unacknowledged
        + data.drp_alerts.unacknowledged
        + data.drp_takedowns.unacknowledged
      );
    }

    return 0;
  }, [data]);

  const totalNotifications = totalUnacknowledged + totalUnread;

  const getItem = (type: NotificationsType) => ({
    [NotificationsType.INTEL_FINISHED]: {
      title: 'Finished intelligence',
      path: 'intelligence',
    },
    [NotificationsType.INTEL_RAW]: {
      title: 'Raw intelligence',
      path: 'intelligence#raw',
    },
    [NotificationsType.VULNERABILITIES]: {
      title: 'Vulnerabilities',
      path: 'vulnerabilities',
    },
    [NotificationsType.DRP_ALERTS]: {
      title: 'Incidents',
      path: 'incidentmanagement',
    },
    [NotificationsType.DRP_TAKEDOWNS]: {
      title: 'Takedowns',
      path: 'incidentmanagement#takedowns',
    },
    [NotificationsType.RFI]: {
      title: 'RFI',
      path: 'servicerequests',
    },
  }[type]);

  const getUnread = (type: NotificationsType) => (data ? data[type].unread : 0);
  const getUnacknowledged = (type: NotificationsType) => (data ? data[type].unacknowledged : 0);

  const unreadItems = [
    NotificationsType.INTEL_FINISHED,
    NotificationsType.INTEL_RAW,
    NotificationsType.VULNERABILITIES,
    NotificationsType.DRP_ALERTS,
    NotificationsType.DRP_TAKEDOWNS,
    NotificationsType.RFI,
  ];

  const unacknowledgedItem = [
    NotificationsType.VULNERABILITIES,
    NotificationsType.DRP_ALERTS,
    NotificationsType.DRP_TAKEDOWNS,
  ];

  return {
    unreadItems,
    unacknowledgedItem,
    isLoading,
    refetch,
    getUnread,
    getUnacknowledged,
    getItem,
    totalNotifications,
    totalUnread,
    totalUnacknowledged,
  };
};
