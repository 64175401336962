import InformationReliability from '@/components/content/informationreliability/InformationReliability';
import type { TemplateProps } from '../types';
import CatalogFooter from './CatalogFooter';

const Sources = ({ data }: TemplateProps) => (
  <CatalogFooter data={data}>
    <InformationReliability value={data.reliability} />
  </CatalogFooter>
);

export default Sources;
