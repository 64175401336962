import { Group, Text } from '@mantine/core';

import { toIsoDate } from '@/utils/toDate';
import type { TemplateProps } from '../types';

import classes from './Results.module.css';

const CatalogFooter = ({ data, children }: TemplateProps) => (
  <div className={classes.footer}>
    <Group align="center" gap={10}>
      <Text size="sm">
        Last Updated: {toIsoDate(data.updated_at)}
      </Text>

      {children}
    </Group>
  </div>
);

export default CatalogFooter;
