import { useCallback } from 'react';

import useUrlStore, { type Options as URLStateOptions } from './useUrlStore';

interface UseMercuryPaginationArguments extends URLStateOptions {
  name?: string;
}

export const useMercuryPagination = ({
  name,
  namespace,
}: UseMercuryPaginationArguments = {}) => {
  const stateName = name ?? 'p';
  const [state, actions] = useUrlStore({ [stateName]: 1 }, { namespace, urlWatch: stateName });

  const page = Number(state[stateName]);

  const handlePagination = useCallback((p: number) => {
    actions.set({ [stateName]: p });
  }, []);

  const next = () => handlePagination(page + 1);

  const previous = () => handlePagination(page - 1);

  return [
    page,
    {
      handlePagination,
      next,
      previous,
    },
  ] as const;
};
