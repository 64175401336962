import Result from './Result';
import ResultsWrapper from './ResultsWrapper';
import MercuryPagination from '@/components/content/pagination/Pagination';

import { useMercuryPagination } from '@/hooks/useMercuryPagination';
import { useMetisQuery } from '@/services/opensearch';
import { metisKeys } from '@/services/keys';

import type { ResultProp } from '../types';
import type { OpenSearchResult } from '@/types/mercury-data-types/opensearch';

const Results = ({
  endpoint,
  filterBuilder,
  template,
  query,
  filters,
  title,
  tracker,
}: ResultProp) => {
  const [page, { handlePagination }] = useMercuryPagination();

  const results = useMetisQuery({
    endpoint,
    queryKey: metisKeys.records(endpoint),
    params: {
      start: page,
      filter: [...filterBuilder(filters), query],
      length: 10,
    },
    select: (data: OpenSearchResult) => ({
      ...data,
      pages: Math.ceil(data.count / 10),
    }),
  });

  return (
    <div>
      <ResultsWrapper
        isPending={results.isPending}
        isFetching={results.isFetching}
        isError={results.isError}
        error={results.error}
        refetch={results.refetch}
        count={results.data?.count || 0}
        noMatchText={title}
      >
        {results.data?.records.map((record) => (
          <Result record={record} tracker={tracker} template={template} key={record.id} />
        ))}

        <MercuryPagination
          value={page}
          total={results.data?.pages as number}
          onChange={handlePagination}
        />
      </ResultsWrapper>
    </div>
  );
};

export default Results;
