import { Box, Text } from '@mantine/core';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, optional, string } from 'superstruct';

// Components
import Input from '@/components/content/form/components/Input';
import Form, { FormContent } from '@/components/content/form/Form';
import Button from '@/components/content/button/Button';

// Hooks
import { useModal } from '@/hooks/useModal';

// Api
import { useCompleteRegistrationMutation } from '../services/completeRegistration';

// Types
import type { OnSubmitFunc } from '@/types/forms';
import { RegisterFormFields, RegisterFormLabels } from '../types';

// Misc
import { PASSWORD_INFO, REGISTER_DISCLAIMER } from '@/constants/text/english';
import { sanitize } from '@/utils/sanitize';
import { match, password, required } from '@/utils/inputValidation';

interface RegisterFormProps {
  prefillData?: RegisterFormFields & {
    phone?: number
  },
  token: string;
  external: boolean
}

const RegisterForm = ({ prefillData, token, external }: RegisterFormProps) => {
  const { openModal, Modal, modalProps } = useModal();
  const {
    mutate: completeRegistration,
    isPending: isCompleting,
    isSuccess: isCompleted,
  } = useCompleteRegistrationMutation(token);

  const passwordSchema = external ? null : {
    password: password('Password is not valid'),
    confirm_password: match('password', 'Passwords don\'t match'),
  };

  const schema = ST({
    firstname: required(string(), ''),
    lastname: required(string(), ''),
    email: optional(string()),
    phone: optional(string()),
    client_name: optional(string()),
    ...passwordSchema,
  });

  const onSubmit: OnSubmitFunc<RegisterFormFields> = (formData, setGeneralError) => {
    const body = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: prefillData?.phone,
      password: external ? '' : formData.password,
    };

    completeRegistration(body, {
      onSuccess: () => {
        openModal();
      },
      onError: (error) => {
        setGeneralError(error);
      },
    });
  };

  return (
    <>
      <Form<RegisterFormFields>
        onSubmit={onSubmit}
        submitButton={{ text: 'Register', disabled: isCompleting || isCompleted }}
        options={{ resolver: superstructResolver(schema), defaultValues: prefillData }}
        name="register-form"
        fields={RegisterFormLabels}
      >
        {({ register, formState: { errors } }) => (
          <fieldset disabled={isCompleted || isCompleting}>
            <Input<RegisterFormFields>
              register={register}
              type="text"
              name="email"
              label={RegisterFormLabels.email}
              readOnly
              required
            />
            <Input<RegisterFormFields>
              register={register}
              type="text"
              name="firstname"
              label={RegisterFormLabels.firstname}
              error={errors?.firstname}
              required
            />
            <Input<RegisterFormFields>
              register={register}
              type="text"
              name="lastname"
              label={RegisterFormLabels.lastname}
              error={errors?.lastname}
              required
            />
            <Input<RegisterFormFields>
              register={register}
              type="text"
              name="client_name"
              label={RegisterFormLabels.client_name}
              readOnly
              required
            />

            {!external && (
              <>
                <Input<RegisterFormFields>
                  register={register}
                  type="password"
                  name="password"
                  info={PASSWORD_INFO}
                  label={RegisterFormLabels.password}
                  error={errors?.password}
                  displayError
                  required
                />
                <Input<RegisterFormFields>
                  register={register}
                  type="password"
                  name="confirm_password"
                  label={RegisterFormLabels.confirm_password}
                  error={errors?.confirm_password}
                  displayError
                  required
                />
              </>
            )}

            <FormContent dangerouslySetInnerHTML={{ __html: sanitize(REGISTER_DISCLAIMER) }} />
          </fieldset>
        )}
      </Form>

      <Modal
        title="Registration Complete"
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
        {...modalProps}
      >
        <Text>You have successfully registered. You can now log in to Mercury. </Text>

        <Box mt={15}>
          <Button href="/login" asLink>
            Go to login
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RegisterForm;
