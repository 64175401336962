import { buildMetaQuery } from '@/features/meta/services/getMeta';
import { intelligenceKeys } from './keys';

import type { UserId } from '@/types/api';

export const rawMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: intelligenceKeys.meta('raw'),
  endpoint: 'intel/raw/table',
  userId,
});

export const finishedMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: intelligenceKeys.meta('finished'),
  endpoint: 'intel/finished/table',
  userId,
});
