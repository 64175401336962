import { useMemo, useRef, type SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify, type IParseOptions, type IStringifyOptions } from 'qs';
import deepmerge from 'deepmerge';
import { useIsFirstRender } from '@mantine/hooks';
import { useMemoizedFn, useUpdate } from 'ahooks';
import { getEntries } from '@/utils';

export interface Options {
  navigateMode?: 'push' | 'replace';
  parseOptions?: IParseOptions & { commaRoundTrip?: boolean };
  stringifyOptions?: IStringifyOptions & { commaRoundTrip?: boolean };
  namespace?: string;
  nest?: Array<string>;
  urlWatch?: string;
  baseState?: Record<string, any>;
}

export type UrlStateActions = {
  set: (s: SetStateAction<Record<string, any>>, options?: DispatchOptions) => void;
  clear: (s?: Array<string> | string, options?: DispatchOptions) => void;
  reset: (s?: Array<string> | string) => void;
};

type DispatchOptions = {
  forceState?: boolean;
};

const charsMapping = {
  '&': 'AND',
};

const encode = (str: string | undefined) => {
  const replaced = Object.entries(charsMapping).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(key, 'g'), `_${value}_`),
    str ?? '',
  );
  return replaced;
};

const decode = (str: string | undefined) => {
  const replaced = Object.entries(charsMapping).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(`_${value}_`, 'g'), key),
    str ?? '',
  );
  return replaced;
};

const baseParseConfig: IParseOptions = {
  ignoreQueryPrefix: true,
  decoder(value) {
    return decodeURIComponent(typeof value === 'string' ? decode(value) : value);
  },
};

const baseStringifyConfig: IStringifyOptions = {
  skipNulls: false,
  encodeValuesOnly: true,
  encoder: (value) => encodeURIComponent(typeof value === 'string' ? encode(value) : value),
};

type UrlState = Record<string, any>;

/**
 * From ahook/useUrlState. Forked to replase the underlying query string parser.
 */
const useUrlState = <S extends UrlState = UrlState>(
  initialState?: S,
  options?: Options,
) => {
  type State = Partial<{ [key in keyof S]: any }>;

  const { navigateMode = 'push', parseOptions, stringifyOptions } = options || {};
  const mergedParseOptions = { ...baseParseConfig, ...parseOptions };
  const mergedStringifyOptions = { ...baseStringifyConfig, ...stringifyOptions };

  const defaultState = useMemo(() => ({}), []);
  const location = useLocation();

  const navigate = useNavigate();
  const update = useUpdate();

  const isCleared = useRef(false);

  const namespace = (query: UrlState) => (options?.namespace ? { [options.namespace]: query } : query);

  // Nest the query for state setting
  const nestQuery = (query: UrlState) => (options?.nest || []).reduceRight((acc, key) => ({ [key]: acc }), query);

  // Get the nested query for the returned state
  const getNested = (query: UrlState) => {
    const nested = (options?.namespace ? query[options.namespace] : query) ?? {};

    return (options?.namespace, options?.nest || []).reduce((acc, key) => acc[key] ?? {}, nested);
  };

  const firstRender = useIsFirstRender();

  const initialStateMemo: Record<string, any> = useMemo(() => (
    typeof initialState === 'function' ? (initialState as () => S)() : initialState || defaultState
  ), [initialState]);

  // Get ALL of the query from the URL
  const queryFromUrl = useMemo(() => (
    parse(decodeURIComponent(location.search), mergedParseOptions)
  ), [location.search]);

  // Calculate the target query to get only the nested url
  const t = () => {
    const nested = getNested(queryFromUrl) as State;
    const hasValuesInUrl = Object.keys(
      options?.urlWatch ? nested[options.urlWatch] ?? {} as State : nested,
    ).length > 0;

    if (hasValuesInUrl || (isCleared.current && !firstRender)) {
      return nested ?? {};
    }

    return { ...initialStateMemo, ...nested ?? {} } as State;
  };

  // Return query
  const targetQuery: State = t();

  const getQueryAsUndefined = () => (
    getEntries(targetQuery).reduce((acc, [key]) => ({ ...acc, [key]: undefined }), {})
  );

  const setState = (
    s: SetStateAction<State | any>,
    dispatchOptions?: DispatchOptions,
  ) => {
    const newQuery = typeof s === 'function' ? s(targetQuery) : s;

    const resultQuery = deepmerge(
      queryFromUrl,
      namespace({
        ...nestQuery(
          deepmerge(
            (dispatchOptions?.forceState ? {} : targetQuery),
            newQuery,
            { arrayMerge: (_, source) => source },
          ),
        ),
        ...(options?.baseState ?? {}),
      }),
      { arrayMerge: (_, source) => source },
    );

    const queryString = stringify(
      resultQuery,
      mergedStringifyOptions,
    );

    update();

    navigate(
      {
        hash: location.hash,
        search: queryString || '?',
      },
      {
        replace: navigateMode === 'replace',
        state: location.state,
      },
    );
  };

  const clear = (
    s?: Array<string> | string,
    dispatchOptions?: DispatchOptions,
  ) => {
    isCleared.current = true;

    // Clean the whole state if no argument is passed
    if (!s) {
      setState(getQueryAsUndefined());
      return;
    }

    // Clean the specified keys
    const target = () => {
      if (Array.isArray(s)) {
        return s.reduce((accu, curr) => ({ ...accu, [curr]: undefined }), {});
      }

      // Nest and get the las key undefined
      const keys = s.split('.');
      const lastKey = keys.at(-1) as string;
      const nested = keys.reduceRight((acc, key) => ({ [key]: key === lastKey ? undefined : acc }), {});
      return { [lastKey]: undefined, ...nested };
    };

    const newQuery = { ...targetQuery, ...target() };

    setState(newQuery, dispatchOptions);
  };

  const reset = (s?: Array<string> | string) => {
    isCleared.current = false;

    if (!s) {
      // Set everything in the current nested url as undefined
      const undefinedQuery = getQueryAsUndefined();

      // Reset the state to the initial state and clean the existing nested state
      setState(
        { ...undefinedQuery, ...initialStateMemo },
        { forceState: true },
      );

      return;
    }

    // Reset the specified keys
    const target = Array.isArray(s)
      ? s.reduce((accu, curr) => ({ ...accu, [curr]: initialStateMemo[curr] ?? undefined }), {})
      : { [s]: initialStateMemo[s] ?? undefined };

    setState(target);
  };

  return [
    targetQuery,
    {
      set: useMemoizedFn(setState),
      clear: useMemoizedFn(clear),
      reset: useMemoizedFn(reset),
    },
  ] as const;
};

export default useUrlState;
