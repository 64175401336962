import { getLocalTimeZone, today as getToday } from '@internationalized/date';

export const today = getToday(getLocalTimeZone());
export const yesterday = today.subtract({ days: 1 });
export const lastWeek = today.subtract({ days: 7 });
const lastMonth = today.subtract({ months: 1 });

export const defaultPresets = [
  {
    label: 'Today',
    value: { start: today, end: today },
  },
  {
    label: 'Yesterday',
    value: { start: yesterday, end: yesterday },
  },
  {
    label: 'Last 7 days',
    value: { start: lastWeek, end: today },
    preview: `${lastWeek.toString()} to ${today.toString()}`,
  },
  {
    label: 'Last 30 days',
    value: { start: lastMonth, end: today },
    preview: `${lastMonth.toString()} to ${today.toString()}`,
  },
];
