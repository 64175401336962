// Hooks
import { useAuth } from '@/hooks/useAuth';
import Linkv2 from '@/components/content/link/Linkv2';

// Api
import { useNotificationsMarkAllAsReadMutation } from '../services/markAllAsRead';
import { Center } from '@mantine/core';

interface NotificationsMenuItemHeaderProps {
  refetch: () => void;
}

const NotificationsMenuItemHeader = ({ refetch }: NotificationsMenuItemHeaderProps) => {
  const { userInfo } = useAuth();
  const { mutate: markAllAsReadMutation } = useNotificationsMarkAllAsReadMutation(userInfo.user_id);

  const onClick = () => {
    // Mark all the issues as read in background
    markAllAsReadMutation({}, {
      onSuccess: () => {
        // TODO - Invalidate cache to update the notifications counter
        refetch();
      },
      onError: (error) => { console.error(error); },
    });
  };

  return (
    <Center mt={15}>
      <Linkv2 as="button" onClick={onClick}>Mark all as read</Linkv2>
    </Center>
  );
};

export default NotificationsMenuItemHeader;
