import { useMetisQuery } from '@/services/opensearch';

import type { ResultTabProps } from '../types';
import type { OpenSearchResult } from '@/types/mercury-data-types/opensearch';
import { ERROR_CLASSES } from '@/types/api';
import { abbreviateNumber } from '@/utils/number';
import { metisKeys } from '@/services/keys';

const ResultTab = ({
  result: { title, endpoint, filterBuilder, resultLimit },
  q,
  filters,
}: ResultTabProps) => {
  const countQuery = useMetisQuery<OpenSearchResult>({
    queryKey: metisKeys.records(endpoint),
    endpoint,
    params: {
      start: 0,
      filter: [...filterBuilder(filters), q],
      length: 0,
    },
  });

  const manyOrNumber = (count: number) => `${abbreviateNumber(count)}${resultLimit && count >= resultLimit ? '+' : ''}`;

  const count = () => {
    if (countQuery.isError) {
      if (countQuery.error?.code === 402) {
        return manyOrNumber(countQuery.error.details?.count ?? 0);
      }

      if (countQuery.error?.code === 400 && countQuery.error.details?.class === ERROR_CLASSES.QUERY_ATTRIBUTE_ERROR) {
        return 0;
      }

      return '?';
    }

    return manyOrNumber(countQuery.data?.count ?? 0);
  };

  return (
    <div>
      {title} ({count()})
    </div>
  );
};

export default ResultTab;
