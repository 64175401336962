import { useSearchParams } from 'react-router-dom';
import { Box, Center, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import MercuryTabs from '@/components/content/tabs/Tabs';
import Results from '../components/Results';
import Title from '@/components/content/title/Title';
import { MercuryCol, MercuryGrid } from '@/components/gridv2/Grid';
import FullPageContent from '@/components/content/box/FullPageContent';
import NavBarSearchBar from '@/components/layout/nav/topbar/searchbar/NavBarSearchBar';
import ResultTab from '../components/ResultTab';
import SearchFilters from '../components/SearchFilters';

import { useMetaStore } from '@/hooks/useMetaStore';
import { results } from './results.config';

import classes from '../components/Results.module.css';

const Search = () => {
  const [searchParams] = useSearchParams();
  const metaStore = useMetaStore({
    baseState: { p: 1 },
  });

  const matches = useMediaQuery('(min-width: 62em)');
  const q = searchParams.get('q') ?? '';

  if (!q) {
    return (
      <FullPageContent>
        <Center>
          <Text size="xl">Find what you are looking for</Text>
        </Center>

        <NavBarSearchBar />
      </FullPageContent>
    );
  }

  const tabs = results.map((result) => ({
    val: result.tab,
    tab: result.customTab
      ? (
        <result.customTab
          result={result}
          q={q}
          filters={metaStore.filters.state}
        />
      ) : (
        <ResultTab
          result={result}
          q={q}
          filters={metaStore.filters.state}
        />
      ),
  }));

  return (
    <MercuryGrid>
      <MercuryCol transparent>
        <Box maw={{ lg: '80%' }}>
          <Title as="h1">Showing Results for: <b>{q}</b></Title>

          <SearchFilters metaStore={metaStore} />

          <MercuryTabs
            tabs={tabs}
            orientation={matches ? 'vertical' : 'horizontal'}
            id="search-results"
            renderSelect={!matches}
            mt={25}
            urlObject={{ p: 1 }}
            small
            saveTabInUrl
            sticky
          >
            {results.map((result) => (
              <div className={classes.tabContent} key={result.endpoint}>
                {result.customResult ? (
                  <result.customResult
                    query={q}
                    filters={metaStore.filters.state}
                    {...result}
                  />
                ) : (
                  <Results
                    query={q}
                    filters={metaStore.filters.state}
                    {...result}
                  />
                )}
              </div>
            ))}
          </MercuryTabs>
        </Box>
      </MercuryCol>
    </MercuryGrid>
  );
};

export default Search;
