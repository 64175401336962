import {
  ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { useSamlStart } from '../services/saml';
import { BackButton, WelcomeText } from '../styles/auth.styles';

const SAML_TIMEOUT = 5;
const SAML_INTERVAL = 1000;

interface SamlRedirectProps {
  onCancel: VoidFunction;
  renderMessage: () => ReactNode;
  username: string;
}

const CancelButton = styled(BackButton)`
  display: block;
  margin-top: 15px;
  width: 100% !important;
  text-align: center;
`;

const SamlRedirect = ({ onCancel, renderMessage, username }: SamlRedirectProps) => {
  const [count, setCount] = useState(SAML_TIMEOUT);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const { mutate: startSaml, isPending: isRedirecting } = useSamlStart();

  const decrementCount = useCallback(() => {
    setCount((oldTimer) => oldTimer - 1);
  }, []);

  useEffect(() => {
    if (count <= 0) {
      startSaml({ username });
      return undefined;
    }

    const timeoutFunction = setInterval(decrementCount, SAML_INTERVAL);
    intervalRef.current = timeoutFunction;

    return () => clearInterval(timeoutFunction);
  }, [decrementCount, count]);

  return (
    <>
      {renderMessage()}

      <WelcomeText>
        Redirecting to single sign-on <b>{count <= 0 ? '...' : `in ${count}`}</b>
      </WelcomeText>

      <CancelButton onClick={onCancel} disabled={isRedirecting}>
        Cancel Redirection
      </CancelButton>
    </>
  );
};

export default SamlRedirect;
