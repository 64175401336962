export function findInObject(
  obj: Record<string, any>,
  findDescendant: Array<string>,
  fallbackDefault?: any,
  transform?: (value: any) => any,
): any {
  const getProp = (q: string) => (
    q.split('.').reduce((accu, curr) => (accu && accu[curr] ? accu[curr] : undefined), obj)
  );

  let value;

  for (let i = 0; i < findDescendant.length; i += 1) {
    const prop = findDescendant[i];
    value = getProp(prop);

    if (value) {
      break;
    }
  }

  const defValue = value ?? fallbackDefault;

  return transform ? transform(defValue) : defValue;
}
