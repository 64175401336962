export const catalogsKeys = {
  all: ['catalog'] as const,

  catalog: (catalog: string) => [...catalogsKeys.all, catalog] as const,

  meta: (catalog: string) => [...catalogsKeys.catalog(catalog), 'meta'] as const,

  entity: (catalog: string, id: string | number) => [...catalogsKeys.catalog(catalog), id] as const,

  entityGfx: (catalog: string, id: number) => [...catalogsKeys.catalog(catalog), 'gfx', id] as const,

  gfx: (catalog: string) => [...catalogsKeys.catalog(catalog), 'gfx'] as const,

  filters: (catalog: string, filter: string) => [...catalogsKeys.catalog(catalog), 'filters', filter] as const,

  related: (catalog: string, entity: number) => [...catalogsKeys.catalog(catalog), 'related', entity] as const,

  relatedMeta: (catalog: string, entity: number) => [...catalogsKeys.related(catalog, entity), 'meta'] as const,

  attckoverview: () => [...catalogsKeys.catalog('attck'), 'overview'] as const,
};
