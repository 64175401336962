import ResultsWrapper from './ResultsWrapper';
import Message from '@/components/content/message/Message';
import Signal from './Signal';
import MercuryPagination from '@/components/content/pagination/Pagination';

import { useQiQlQuery } from '@/services/opensearch';
import { useMessage } from '@/hooks/useMessage';
import { useMercuryPagination } from '@/hooks/useMercuryPagination';
import { signalsKeys } from '@/services/keys';

import { SIGNALS_BETA_MESSAGE } from '@/constants/text/english';

import type { ResultProp } from '../types';
import type { OpenSearchResult } from '@/types/mercury-data-types/opensearch';

const SignalResult = ({ query, endpoint, filters, filterBuilder }: ResultProp) => {
  const [page, { handlePagination }] = useMercuryPagination();

  const { removeMessage, message } = useMessage({
    text: SIGNALS_BETA_MESSAGE,
    type: 'success',
  });

  const signals = useQiQlQuery({
    queryKey: signalsKeys.all,
    endpoint,
    params: {
      start: page,
      filter: [...filterBuilder(filters), query],
      length: 10,
    },
    select: (data: OpenSearchResult) => ({
      ...data,
      pages: Math.ceil(data.count / 10),
    }),
  });

  const pages = signals.data?.pages;

  return (
    <div>
      <Message message={message} removeMessage={removeMessage} />

      <ResultsWrapper
        isPending={signals.isPending}
        isFetching={signals.isFetching}
        isError={signals.isError}
        error={signals.error}
        refetch={signals.refetch}
        count={signals.data?.records.length || 0}
        noMatchText="Signals"
      >
        {signals.data?.records.map((signal) => (
          <Signal signal={signal} />
        ))}

        <MercuryPagination
          value={page}
          total={pages as number}
          onChange={handlePagination}
        />
      </ResultsWrapper>
    </div>
  );
};

export default SignalResult;
