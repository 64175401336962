import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { RichText } from '../markdown/Markdown';

const FullPageWrapperStyled = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
`;

const FullPageContentStyled = styled(RichText)`
  background-color: var(--grid-column-bg);
  border-radius: var(--border-radius);
  padding: 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    width: 750px;
  }

  h1 {
    color: var(--quo-power-75);
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 1.4;

    @media (min-width: ${(props) => props.theme.breakpoints.M}) {
      font-size: 22px;
    }
  }

  button:not(.icon-button) {
    align-self: center;
    margin-top: 25px;
  }
`;

const FullPageContent = ({ children }: PropsWithChildren<{}>) => (
  <FullPageWrapperStyled>
    <FullPageContentStyled>{children}</FullPageContentStyled>
  </FullPageWrapperStyled>
);

export default FullPageContent;
