export enum Cardinalities {
  range = 'range',
  multiple = 'multiple',
  single = 'single',
}

export const FrontendCategories = {
  context: 'context_filter',
  entity: 'entity_filter',
} as const;

export type FrontendCategoriesTypes = typeof FrontendCategories[keyof typeof FrontendCategories];

export type ArrayFECateogries = Array<FrontendCategoriesTypes>;

export const FiltersTypes = {
  date: 'date',
  string: 'string',
  category: 'category',
  boolean: 'boolean',
} as const;

export type TFiltersTypes = typeof FiltersTypes[keyof typeof FiltersTypes];

export interface IMeta {
  name: string;
  label: string;
  description: string;
  type: TFiltersTypes;
  cardinality: Cardinalities;
  frontend_category?: FrontendCategoriesTypes;
  default_choice?: string;
  choices?: Array<Choice>;
  non_meta?: boolean;
}
type Range = {
  min: string;
  max: string;
};

export interface TDate extends IMeta {
  range: Range;
}

type Choice = {
  value: string | number;
  label: string;
  description: string;
  parent_id: number | null;
  parent_cat?: string | null;
};

export interface Multiple extends IMeta {
  choices: Array<Choice>;
  layout: 'checkbox';
}

export interface Single extends IMeta { }

export type Filters = TDate | Multiple | Single;

export type FiltersMeta = Array<Filters>;

export interface DispatchOptions {
  isNonMeta?: boolean;
}
