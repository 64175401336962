import type { NotificationsData } from '../types';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';
import { notificationsKeys } from './keys';
import { useAuth } from '@/hooks/useAuth';

export const useGetNotifications = () => {
  const { userInfo: { user_id: userId } } = useAuth();

  return (
    useQuery<NotificationsData>({
      queryKey: notificationsKeys.notifications(),
      queryFn: () => fetchData({ endpoint: `mercury/notifications/${userId}` }),
    })
  );
};
