const BASE_PATHS = {
  COMMON: 'common',
  STRUCTURED: 'data.structured',
  CATALOG: 'catalog',
} as const;

export const CLUSTERS_PATHS = {
  ID: [
    'cluster_id',
  ],
  TITLE: [
    `${BASE_PATHS.STRUCTURED}.title`,
  ],
  TEXT: [
    `${BASE_PATHS.STRUCTURED}.summary`,
  ],
  FIRST_SEEN: [
    `${BASE_PATHS.STRUCTURED}.firstSeen`,
  ],
  LAST_SEEN: [
    `${BASE_PATHS.STRUCTURED}.lastSeen`,
  ],
  TRENDS_SCORE: [
    'trend_score',
  ],
  TOTAL_ARTICLES: [
    'articles',
  ],
  TOTAL_SOURCES: [
    `${BASE_PATHS.CATALOG}.source`,
  ],
  CATEGORY: [
    `${BASE_PATHS.CATALOG}.category`,
  ],
  INDUSTRY: [
    `${BASE_PATHS.CATALOG}.industrysector`,
  ],
  COUNTRY: [
    `${BASE_PATHS.CATALOG}.country`,
  ],
};
