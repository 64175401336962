import { useQuery } from '@tanstack/react-query';
import { getMutation } from '@/lib/react-query/mutate';

import { fetchData } from '@/lib/fetch';
import { authKeys } from './keys';

import type { RegisterFormFields, UserInfo } from '../types';
import { FetchError } from '@/types/api';

const userinfo = 'userinfo';
const userinfotoken = (token: string) => `auth/token/userinfo/${token}`;

export const useGetUserInfoMutation = <TData = UserInfo, TTVariables = unknown>() => (
  getMutation<TData, TTVariables>({
    endpoint: userinfo,
    requestOptions: {
      error: 'There was a technical problem please try again',
    },
  })
);

export const useGetUserInfoQuery = <T = UserInfo>() => (
  useQuery<T>({
    queryKey: authKeys.userinfo(),
    queryFn: () => fetchData({
      endpoint: userinfo,
      error: 'Couldn\'t verify user',
    }),
    meta: {
      avoidAuthRedirection: true,
    },
  })
);

export const useGetUserInfoByToken = (token: string) => (
  useQuery<RegisterFormFields & { phone: number; external_auth: boolean; }, FetchError>({
    queryKey: authKeys.userInfoToken(token),
    queryFn: () => fetchData({
      endpoint: userinfotoken(token),
      error: 'Couldn\'t verify user',
    }),
    meta: {
      avoidAuthRedirection: true,
    },
  })
);
