import styled from 'styled-components';
import NotificationsMenuItemType from './MenuItemType';

import { NotificationsType } from '../types';

const Muted = styled.span`
  font-size: 22px;
  color: var(--quo-grey-50);
  text-align: center;
`;

interface NotificationProps {
  items: Array<NotificationsType>,
  getNotification: (type: NotificationsType) => number,
  getItem: (type: NotificationsType) => ({ title: string; path: string }),
  total: number,
  emptyMsg: string,
  footer?: JSX.Element | null;
}

const Notification = ({ items, getNotification, getItem, total, emptyMsg, footer: Footer }: NotificationProps) => (
  <>
    <ul>
      {
        items.map((type) => {
          const notification = getNotification(type);

          return notification > 0 ? (
            <NotificationsMenuItemType
              key={type}
              notification={notification}
              item={getItem(type)}
            />
          ) : null;
        })
      }
    </ul>

    {Footer}

    {total === 0 && (<Muted>No {emptyMsg} notifications</Muted>)}
  </>
);

export default Notification;
