import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, string } from 'superstruct';

// Components
import Input from '@/components/content/form/components/Input';
import Form from '@/components/content/form/Form';
import Title from '@/components/content/title/Title';

// Api
import { useSendPasswordRecoveryMutation } from '@/services/password';

// Types
import type { OnSubmitFunc } from '@/types/forms';
import { ForgotPasswordFields, ForgotPasswordLabels } from '../types';

// Misc
import { required } from '@/utils/inputValidation';

// Styles
import { PASSWORD_RECOVERY_SENT } from '@/constants/text/english';
import { RichText } from '@/components/content/markdown/Markdown';
import { Wrapper, Content } from '../styles/auth.styles';

const ForgotPassword = () => {
  const { isPending: isRecoveringPassword, mutate: postSendRecovery } = useSendPasswordRecoveryMutation();

  const schema = ST({
    email: required(string(), 'Email is required'),
  });

  const sendRecovery: OnSubmitFunc<ForgotPasswordFields> = (formData, setGeneralError, setSuccessMessage) => {
    postSendRecovery(formData, {
      onSuccess: () => {
        setSuccessMessage(PASSWORD_RECOVERY_SENT, true, { customHtml: true });
      },
      onError: (error) => {
        setGeneralError(error);
      },
    });
  };

  return (
    <Wrapper>
      <Content>
        <Title as="h1">
          Password Reset
        </Title>

        <RichText>
          <p>
            Please enter the email address you used to register your account.
            We will send you a link that you can use to reset your password.
          </p>
        </RichText>

        <Form<ForgotPasswordFields>
          onSubmit={sendRecovery}
          submitButton={{
            text: 'Reset Password',
            disabled: isRecoveringPassword,
          }}
          options={{ resolver: superstructResolver(schema) }}
          fields={ForgotPasswordLabels}
        >
          {({ register, formState: { errors } }) => (
            <Input<ForgotPasswordFields>
              register={register}
              type="text"
              name="email"
              label={ForgotPasswordLabels.email}
              error={errors?.email}
              required
            />
          )}
        </Form>
      </Content>
    </Wrapper>
  );
};

export default ForgotPassword;
