import { ACCESS_ROLES } from '@/constants/permissions';

const { ROLE_ADMIN, ROLE_ANALYST, ROLE_SERVICE_USER, ROLE_CLIENT_ANALYST } = ACCESS_ROLES;

export const isAdmin = (role: string) => role === ROLE_ADMIN;

export const isServiceUser = (role: string) => role === ROLE_SERVICE_USER;

export const isAnalyst = (role: string) => role === ROLE_ANALYST;

export const isClientAnalyst = (role: string) => role === ROLE_CLIENT_ANALYST;
