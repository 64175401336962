import { Text } from '@mantine/core';

import { toIsoDate } from '@/utils/toDate';
import classes from './Results.module.css';

const IssueFooter = ({ data }: { data: any }) => (
  <div className={classes.footer}>
    <Text size="sm">
      Date: {toIsoDate(data.created_at)}
    </Text>
  </div>
);

export default IssueFooter;
