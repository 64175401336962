import { useSearchParams } from 'react-router-dom';

// Components
import RegisterForm from '../components/RegisterForm';
import ErrorWrapper from '@/components/content/error/ErrorWrapper';

// Api
import { useGetUserInfoByToken } from '../services/getUserInfo';

// Styles
import { Wrapper, Content, Title } from '../styles/auth.styles';
import Skeleton from '@/components/content/placeholder/Skeleton';
import RouterLink from '@/components/content/link/RouterLink';

const Register = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;
  const {
    data,
    isLoading,
    isRefetching,
    isError,
    error,
    refetch,
  } = useGetUserInfoByToken(token);

  const errors = {
    401: {
      message: `Your registration token has expired. Please contact your dedicated customer
      success manager at <a href="mailto:support@quointelligence.eu">support@quointelligence.eu</a> for
      further assistance.`,
    },
    ...Object.fromEntries(
      ['404', '403'].map((k) => [
        k,
        {
          message: `This token has already been used or is invalid, contact your dedicated customer
          success manager at <a href="mailto:support@quointelligence.eu">support@quointelligence.eu</a> for
          further assistance.`,
          action: <RouterLink to="/">Go to Mercury</RouterLink>,
        },
      ]),
    ),
  };

  return (
    <Wrapper>
      <ErrorWrapper isError={isError} error={error} errors={errors} refetch={refetch}>
        <Content>
          {
            (isLoading || isRefetching) ? (
              <Skeleton height={150} />
            ) : (
              <>
                <h1>
                  <Title>Complete Registration</Title>
                </h1>

                <RegisterForm
                  prefillData={{
                    email: data?.email || '',
                    firstname: data?.firstname || '',
                    lastname: data?.lastname || '',
                    client_name: data?.client_name || '',
                  }}
                  external={data?.external_auth || false}
                  token={token}
                />
              </>
            )
          }
        </Content>
      </ErrorWrapper>
    </Wrapper>
  );
};

export default Register;
