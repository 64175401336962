import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components
import TwoFAModal from '../../profileinfo/components/TwoFAModal';
import { RichText } from '@/components/content/markdown/Markdown';

// Hooks
import { useTfa } from '../../profileinfo/hooks/useTfa';
import { useLogout } from '../services/logout';
import { Wrapper, Content, Title } from '../styles/auth.styles';
import { INVALID_FORCED_2FA } from '@/constants/text/english';

const ActivateTFA = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    mutation: { isPending: isLogingout, mutate: serverLogout },
    clientLogout,
  } = useLogout();
  const username = searchParams.get('username') as string;

  const { generateTFA, activateTFA, TFAData, disableButton } = useTfa({
    onActivateSuccess: () => {
      clientLogout();
      serverLogout('');
    },
    onGenerateError: () => {
      navigate('/login', {
        state: {
          display_modal: true,
          modal_title: 'We are sorry, something went wrong.',
          avoidPrecheck: true,
          modal_text: INVALID_FORCED_2FA,
        },
      });
    },
    username,
  });

  useEffect(() => {
    if (!username) {
      navigate('/login');
      return;
    }

    generateTFA();
  }, []);

  return (
    <Wrapper>
      <Content>
        <Title as="h1">2-Factor Authentication needs to be setup</Title>

        <RichText>
          <p>You have been requested to activate 2-factor authentication by your organisation.</p>
          <p>Please set it up to continue using Mercury.</p>
        </RichText>

        {TFAData && (
          <TwoFAModal
            enabled={false}
            data={TFAData}
            tfaAction={activateTFA}
            disableAction={disableButton || isLogingout}
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default ActivateTFA;
