import { Text } from '@mantine/core';

import Markdown from '@/components/content/markdown/Markdown';
import Title from '@/components/content/title/Title';

import { toIsoDate } from '@/utils/toDate';
import { findInObject } from '@/utils/findInObject';
import { SIGNALS_PATHS } from '@/constants/opensearchpaths';

import classes from './Results.module.css';

const Signal = ({ signal }: { signal: any }) => {
  const title = findInObject(signal, SIGNALS_PATHS.TITLE);
  const text = findInObject(signal, SIGNALS_PATHS.TEXT);
  const timestamp = findInObject(signal, SIGNALS_PATHS.TIMESTAMP);

  return (
    <div
      className={classes.resultWrapper}
      data-testid="search-result"
    >
      <Title as="h3">{title}</Title>

      <Text component="div" classNames={{ root: `${classes.signals} ${classes.description}` }}>
        <Markdown text={text} />
      </Text>

      {timestamp && (
        <div className={classes.footer}>
          <Text size="sm">
            Date: {toIsoDate(findInObject(signal, SIGNALS_PATHS.TIMESTAMP))}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Signal;
