import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// Icons
import Dashboard from '@/assets/icons/sidebar/dashboard.svg';
import Analytics from '@/assets/icons/sidebar/analytics.svg';
import Intelligence from '@/assets/icons/sidebar/intelligence.svg';
import Ransomware from '@/assets/icons/sidebar/ransomware.svg';
import EASM from '@/assets/icons/sidebar/easm.svg';
import AnalyticsDashboard from '@/assets/icons/sidebar/analytics-dashboard.svg';
import BrandProtection from '@/assets/icons/sidebar/brand-protection.svg';
import Vulnerabilities from '@/assets/icons/sidebar/vulnerabilities.svg';
import IdentityPanel from '@/assets/icons/sidebar/identity.svg';

import Catalogs from '@/assets/icons/sidebar/catalogs.svg';
import ThreatActor from '@/assets/icons/sidebar/threat-actor.svg';
import Malwares from '@/assets/icons/sidebar/malwares.svg';
import Attck from '@/assets/icons/sidebar/attck.svg';
import Sources from '@/assets/icons/sidebar/sources.svg';
import Request from '@/assets/icons/sidebar/request.svg';
import Providers from '@/assets/icons/sidebar/providers.svg';
import Personas from '@/assets/icons/sidebar/personas.svg';

// Pages
import Page from '@/components/layout/Page';
import RFIPageUpsell from '@/components/content/upsell/RFIPageUpsell';
import Upsell from '@/components/content/upsell/Upsell';
import FullPageContent from '@/components/content/box/FullPageContent';
import Redirect from '@/components/content/redirect/Redirect';
import Search from './features/search/pages/Search';

// Types
import type { RoutePathDefinition } from '../types/routes';

// Misc
import { mercuryPages, MercuryTrackersAccesor } from '../constants/mercuryPages';

import { CATALOGS, MODULES } from '@/constants/permissions';
import { DASHBOARD_UPSELL } from '@/constants/text/english';
import { malwareKitsMetaQuery } from './features/catalogs/features/malwares/services/meta';
import { attckMetaQuery } from './features/catalogs/features/attck/services/meta';
import { vulnerabilitiesMetaQuery } from './features/catalogs/features/vulnerabilities/services/meta';
import { threatActorsMetaQuery } from './features/catalogs/features/threatactors/services/meta';

const Profile = lazy(() => import('../features/profileinfo/pages/ProfileInfo'));
const Subscriptions = lazy(() => import('../features/profileinfo/pages/SubscriptionsInfo'));

const DashboardPage = lazy(() => import('./features/dashboard/pages/Dashboard'));
const IntelligencePage = lazy(() => import('./features/intelligence/pages/Intelligence'));
const RansomwarePage = lazy(() => import('./features/ransomware/pages/Ransomware'));
const EASMPage = lazy(() => import('./features/easm/pages/EASM'));
const AnalyticsPage = lazy(() => import('./features/analyticsdashboard/pages/Analytics'));
const IncidentManagementPage = lazy(() => import('./features/incidentmanagement/pages/IncidentManagement'));
const VulnerabilityIntelligence = lazy(() => import('./features/vulnerabilities/pages/VulnerabilitiesAlerts'));
const IdentityPanelPage = lazy(() => import('./features/identitypanel/pages/IdentityPanel'));

const IntelligenceIssue = lazy(() => import('./features/intelligence/pages/Issue'));
const IncidentManagementIssue = lazy(() => import('./features/incidentmanagement/pages/Issue'));
const VulnerabilitiesIssue = lazy(() => import('./features/vulnerabilities/pages/Issue'));
const IdentityPage = lazy(() => import('./features/identitypanel/pages/Identity'));

const ThreatActors = lazy(() => import('./features/catalogs/features/threatactors/pages/ThreatActors'));
const ThreatActorEntity = lazy(() => import('./features/catalogs/features/threatactors/pages/Entity'));
const MalwaresPage = lazy(() => import('./features/catalogs/features/malwares/pages/Malwares'));
const MalwaresEntity = lazy(() => import('./features/catalogs/features/malwares/pages/Entity'));
const AttCkPage = lazy(() => import('./features/catalogs/features/attck/pages/Attck'));
const AttckEntity = lazy(() => import('./features/catalogs/features/attck/pages/Entity'));
const SourcesPage = lazy(() => import('./features/catalogs/features/sources/pages/Sources'));
const SourceEntity = lazy(() => import('./features/catalogs/features/sources/pages/Entity'));
const ProvidersPage = lazy(() => import('./features/catalogs/features/providers/pages/Providers'));
const ProvidersEntity = lazy(() => import('./features/catalogs/features/providers/pages/Entity'));
const PersonasPage = lazy(() => import('./features/catalogs/features/personas/pages/Personas'));
const PersonasEntity = lazy(() => import('./features/catalogs/features/personas/pages/Entity'));
const VulnerabilitiesCatalogPage = lazy(
  () => import('./features/catalogs/features/vulnerabilities/pages/Vulnerabilities'),
);
const VulnerabilitiesEntity = lazy(() => import('./features/catalogs/features/vulnerabilities/pages/Entity'));

const ServiceRequest = lazy(() => import('./features/servicerequests/pages/ServiceRequests'));
const ServiceRequestsIssue = lazy(() => import('./features/servicerequests/pages/Issue'));

export const clientItems: RoutePathDefinition[] = [
  {
    path: '/',
    title: 'Dashboard',
    index: true,
    element: (
      <Page>
        <DashboardPage />
      </Page>
    ),
    icon: Dashboard,
    nav: true,
  },
  {
    path: 'analytics',
    isSubMenu: true,
    layout: true,
    name: 'Analytics',
    icon: Analytics,
    children: [
      {
        path: 'intelligence',
        element: <Outlet />,
        children: [
          {
            path: '',
            page: mercuryPages.intelligence.id,
            index: true,
            element: (
              <Page documentTitle={mercuryPages.intelligence.title}>
                <IntelligencePage />
              </Page>
            ),
            getPageInfo: true,
            icon: Intelligence,
            nav: true,
          },
          {
            path: 'issue/:issue',
            element: (
              <Page>
                <IntelligenceIssue />
              </Page>
            ),
          },
          {
            path: 'finished/issue/:issue',
            element: (
              <Page>
                <IntelligenceIssue tracker={MercuryTrackersAccesor.finished} />
              </Page>
            ),
          },
          {
            path: 'raw/issue/:issue',
            element: (
              <Page>
                <IntelligenceIssue tracker={MercuryTrackersAccesor.raw} />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'ransomware',
        getPageInfo: true,
        page: mercuryPages.ransomware.id,
        icon: Ransomware,
        nav: true,
        element: (
          <Page
            noAccessRender={(
              <FullPageContent>
                <Upsell upsellType="Analytics Dashboards" upsellText={DASHBOARD_UPSELL} />
              </FullPageContent>
            )}
            requiredModules={MODULES.ransomware.modules}
            documentTitle={mercuryPages.ransomware.title}
          >
            <RansomwarePage />
          </Page>
        ),
      },
      {
        path: 'easm',
        hideOnNoAccess: true,
        page: mercuryPages.easm.id,
        getPageInfo: true,
        icon: EASM,
        nav: true,
        element: (
          <Page requiredModules={MODULES.easm.modules} documentTitle={mercuryPages.easm.title}>
            <EASMPage />
          </Page>
        ),
      },
      {
        path: 'analytics',
        hideOnNoAccess: true,
        page: mercuryPages.analytics.id,
        getPageInfo: true,
        icon: AnalyticsDashboard,
        nav: true,
        element: (
          <Page requiredModules={MODULES.analyticsdashboard.modules} documentTitle={mercuryPages.analytics.title}>
            <AnalyticsPage />
          </Page>
        ),
      },
    ],
  },
  {
    path: 'drp',
    element: <Redirect to="/incidentmanagement" />,
    nav: false,
  },
  {
    path: 'drp/issue/:issue',
    element: <Redirect to="/incidentmanagement/issue/:issue" />,
    nav: false,
  },
  {
    path: 'drp/alert/issue/:issue',
    element: <Redirect to="/incidentmanagement/alert/issue/:issue" />,
    nav: false,
  },
  {
    path: 'drp/takedown/issue/:issue',
    element: <Redirect to="/incidentmanagement/takedown/issue/:issue" />,
    nav: false,
  },
  {
    path: 'incidentmanagement',
    element: <Outlet />,
    children: [
      {
        path: '',
        page: mercuryPages.incidentmanagement.id,
        index: true,
        element: (
          <Page documentTitle={mercuryPages.incidentmanagement.title} withOutlet>
            <IncidentManagementPage />
          </Page>
        ),
        getPageInfo: true,
        icon: BrandProtection,
        nav: true,
      },
      {
        path: 'issue/:issue',
        element: (
          <Page>
            <IncidentManagementIssue />
          </Page>
        ),
      },
      {
        path: 'alert/issue/:issue',
        element: (
          <Page>
            <IncidentManagementIssue tracker={MercuryTrackersAccesor.alerts} />
          </Page>
        ),
      },
      {
        path: 'takedown/issue/:issue',
        element: (
          <Page>
            <IncidentManagementIssue tracker={MercuryTrackersAccesor.takedowns} alertsOnly />
          </Page>
        ),
      },
    ],
  },
  {
    path: 'vulnerabilities',
    element: <Outlet />,
    children: [
      {
        path: '',
        page: mercuryPages.vulnerabilitiesInt.id,
        index: true,
        element: (
          <Page title={mercuryPages.vulnerabilitiesInt.title}>
            <VulnerabilityIntelligence />
          </Page>
        ),
        getPageInfo: true,
        icon: Vulnerabilities,
        nav: true,
      },
      {
        path: 'alert/issue/:issue',
        element: (
          <Page>
            <VulnerabilitiesIssue />
          </Page>
        ),
      },
    ],
  },
  {
    path: 'identitypanel',
    element: <Outlet />,
    children: [
      {
        path: '',
        page: mercuryPages.identitypanel.id,
        index: true,
        element: (
          <Page>
            <IdentityPanelPage />
          </Page>
        ),
        getPageInfo: true,
        icon: IdentityPanel,
        nav: true,
      },
      {
        path: 'entity/:identity',
        element: (
          <Page>
            <IdentityPage />
          </Page>
        ),
      },
    ],
  },
  {
    path: 'catalogs',
    isSubMenu: true,
    layout: true,
    name: 'Catalogs',
    icon: Catalogs,
    children: [
      {
        path: 'catalogs/threatactors',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.threatactors.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.threatactors.modules}
                title={mercuryPages.threatactors.title}
              >
                <ThreatActors />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: ThreatActor,
            prefetch: [threatActorsMetaQuery],
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.threatactors.modules}
              >
                <ThreatActorEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/malwarekits',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.malwarekits.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.malwarekits.modules}
                title={mercuryPages.malwarekits.title}
              >
                <MalwaresPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Malwares,
            prefetch: [malwareKitsMetaQuery],
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.malwarekits.modules}
              >
                <MalwaresEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/providers',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.providers.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.providers.modules}
                title={mercuryPages.providers.title}
              >
                <ProvidersPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Providers,
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.providers.modules}
              >
                <ProvidersEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/attck',
        element: <Outlet />,
        children: [
          {
            path: '',
            page: mercuryPages.attck.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.attck.modules}
                title={mercuryPages.attck.title}
              >
                <AttCkPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Attck,
            prefetch: [attckMetaQuery],
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.attck.modules}
              >
                <AttckEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/sources',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.sources.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.sources.modules}
                title={mercuryPages.sources.title}
              >
                <SourcesPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Sources,
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.sources.modules}
              >
                <SourceEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/vulnerabilities',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.vulnerabilitiesCat.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.vulnerabilities.modules}
                title={mercuryPages.vulnerabilitiesCat.title}
              >
                <VulnerabilitiesCatalogPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Vulnerabilities,
            prefetch: [vulnerabilitiesMetaQuery],
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.vulnerabilities.modules}
              >
                <VulnerabilitiesEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
      {
        path: 'catalogs/personas',
        element: (
          <Outlet />
        ),
        children: [
          {
            path: '',
            page: mercuryPages.personas.id,
            index: true,
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.personas.modules}
                title={mercuryPages.personas.title}
              >
                <PersonasPage />
              </RFIPageUpsell>
            ),
            getPageInfo: true,
            icon: Personas,
            nav: true,
          },
          {
            path: 'entity/:entity',
            element: (
              <RFIPageUpsell
                requiredModules={CATALOGS.personas.modules}
              >
                <PersonasEntity />
              </RFIPageUpsell>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'servicerequests',
    element: <Outlet />,
    children: [
      {
        path: '',
        page: mercuryPages.servicerequests.id,
        title: 'Service Request',
        index: true,
        element: (
          <Page documentTitle={mercuryPages.servicerequests.title}>
            <ServiceRequest />
          </Page>
        ),
        icon: Request,
        getPageInfo: true,
        nav: true,
      },
      {
        path: 'alert/issue/:issue',
        element: (
          <Page>
            <ServiceRequestsIssue />
          </Page>
        ),
      },
    ],
  },
  {
    path: 'account',
    element: (
      <Page>
        <Profile />
      </Page>
    ),
    nav: false,
  },
  {
    path: 'subscriptions',
    element: (
      <Page>
        <Subscriptions />
      </Page>
    ),
    nav: false,
  },
  {
    path: 'search',
    element: <Page><Search /></Page>,
    nav: false,
  },
];
