import { useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// Hooks
import { useAdminRedirect } from './useAdminRedirection';
import { useSession } from '@/hooks/useSession';
import { useAuthType } from './useAuthType';
import { useAuth } from '@/hooks/useAuth';

// Api
import { useGetUserInfoMutation } from '../services/getUserInfo';
import { useAuthMutation } from '../services/login';

// Types
import type { OnSubmitFunc } from '@/types/forms';
import type { LoginFormFields, PrecheckReturn } from '../types';

// Misc
import { isServiceUser } from '@/utils/usersRole';

export const useSignIn = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setUserInfo } = useAuth(false);
  const { startSession } = useSession();
  const { hasAdminRedirect, redirectAdmin } = useAdminRedirect();

  const { isPending: isSigningIn, mutate: signInMutate } = useAuthMutation();
  const { mutate: getProfileInfo, isPending: isGettingUserInfo } = useGetUserInfoMutation();

  const redirectParam = searchParams.get('redirect');
  const state = location.state as { from: Location };
  const from = state?.from || '/';

  const loginPageState = location.state as {
    display_modal: boolean;
    modal_text: string;
    modal_title: string;
    avoidPrecheck: boolean;
  };

  const { precheck, isUnauthorized, isGettingType, setPrecheckData } = useAuthType(loginPageState?.avoidPrecheck);

  const onCancelOrBack = () => {
    setPrecheckData(null);
  };

  const onUnauthorizedPrecheck = (data: PrecheckReturn) => {
    setPrecheckData(data);
  };

  const loadingSignIn = isSigningIn || isGettingUserInfo;

  const isLoginReady = Boolean(!isUnauthorized && precheck);

  const checkingRef = useRef<HTMLDivElement | null>(null);
  const loginRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = isLoginReady ? loginRef : checkingRef;

  const signIn: OnSubmitFunc<LoginFormFields> = (formData, setGeneralError, setSuccessMessage) => {
    const redirectToParam = (redirectTo: string) => {
      window.location.replace(redirectTo);
    };

    const force2fa = (email: string) => {
      navigate(`/activate2fa?username=${encodeURIComponent(email)}`, { state });
    };

    const forceServiceUser = () => {
      setSuccessMessage(`
        You are trying to log in with a service user.
        These users don't have access to Mercury.
        Contact your dedicated Customer Success Manager if you need assistance
      `);
    };

    signInMutate(formData, {
      onSuccess: () => {
        getProfileInfo('', {
          onSuccess: (data) => {
            if (redirectParam) {
              redirectToParam(redirectParam);
              return;
            }

            if (data.two_factor_force_admin && !data.two_factor_enabled) {
              force2fa(data.email);
              return;
            }

            if (hasAdminRedirect(data)) {
              redirectAdmin();
              return;
            }

            if (isServiceUser(data.role)) {
              forceServiceUser();
              return;
            }

            setUserInfo(data);
            startSession();
            navigate(from, { replace: true });
          },
          onError: (error) => setGeneralError(error?.message),
        });
      },
      onError: (error) => setGeneralError(error),
    });
  };

  return {
    signIn,
    loadingSignIn,
    loginPageState,
    onCancelOrBack,
    nodeRef,
    isLoginReady,
    authInfo: precheck,
    isGettingType,
    redirectParam,
    onUnauthorizedPrecheck,
  };
};
