import type { PropsWithChildren } from 'react';
import FullPageContent from '../box/FullPageContent';
import SupportForm from '@/client/features/mailboxes/SupportForm';

import Page, { type PageProps } from '@/components/layout/Page';

interface RFIPageUpsellProps extends PageProps {
}

const RFIPageUpsell = ({
  children,
  ...pageProps
}: PropsWithChildren<RFIPageUpsellProps>) => (
  <Page
    noAccessRender={(
      <FullPageContent>
        <SupportForm
          disabledFields={{ subject: true }}
          prefillData={{
            subject: 'Request for premium trial',
            description: 'I would like to request access to the premium module',
          }}
          subjects={['Request for premium trial']}
          to="support"
        />
      </FullPageContent>
      )}
    {...pageProps}
  >
    {children}
  </Page>
);

export default RFIPageUpsell;
