import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;

  @media (min-width: ${(props) => props.theme.breakpoints.S}) {
    padding-left: 15px;
    padding-right: 15px;
  }

  form button {
    width: 100%;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background: var(--quo-power);
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 30px;
  background: var(--grid-column-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--grid-column-shadow) 0px 0 3px 0px;
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.XS}) {
    max-width: 450px;
    height: auto;
  }

  h1 {
    text-align: center;
    margin: 0;
  }

  form {
    margin-top: 25px;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 26px;
  color: var(--active-color);

  @media (min-width: ${(props) => props.theme.breakpoints.XS}) {
    font-size: 33px;
  }
`;

export const Subtitle = styled.span`
  font-size: 18px;
  margin-top: 5px;
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.XS}) {
    font-size: 22px;
  }
`;

export const ModalText = styled.p`
  margin-top: 15px;
  font-size: 20px;
  line-height: 30px;
`;

export const FormsWrapper = styled(CSSTransition)`
  &.login-fade {
    &-enter-active form > *:not(:last-child) ,
    &-exit-active form > *:not(:last-child) {
      transition: opacity 200ms ease-in-out
    }

    &-enter form > *:not(:last-child)  {
      opacity: 0;
    }

    &-enter-active form > *:not(:last-child)  {
      opacity: 1;
    }

    &-exit form > *:not(:last-child)  {
      opacity: 1;
    }

    &-exit-active form > *:not(:last-child)  {
      opacity: 0;
    }
  }

  > div {
    margin-top: 15px;
  }
`;

export const WelcomeText = styled.span`
  display: block;
  margin-top: 15px;
  text-align: center;
`;

export const BackButton = styled.button`
  margin-left: 5px;
  width: auto !important;

  &:hover {
    background: none !important;
  }
`;
