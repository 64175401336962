import { Center, Pagination, PaginationProps } from '@mantine/core';

import classes from './Pagination.module.css';

interface PaginationWrapperProps extends PaginationProps {
}

const MercuryPagination = ({
  ...paginationProps
}: PaginationWrapperProps) => {
  if (!paginationProps.total) return null;

  return (
    <Center>
      <Pagination
        mt={25}
        boundaries={1}
        siblings={3}
        classNames={{
          control: classes.paginationControl,
        }}
        {...paginationProps}
      />
    </Center>
  );
};

export default MercuryPagination;
