import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface NotificationsMenuItemTypeProps {
  item: {
    title: string;
    path: string;
  };
  notification: number;
}

const TypeLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 10px;
`;

const TypeNotification = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  color: var(--notification-unread-color);
  width: 45px;
  margin-left: auto;
  text-align: center;
`;

const NotificationsMenuItem = styled.li`
  border-bottom: 1px solid var(--dropdown-item-border);

  &:hover {
    background-color: var(--dropdown-item-hover-bg);
  }
`;

const NotificationsMenuItemType = ({ item, notification }: NotificationsMenuItemTypeProps) => {
  const { title, path } = item;

  return (
    <NotificationsMenuItem>
      <TypeLinkContainer to={path}>
        <span><b>{title}</b></span>

        <TypeNotification>
          {notification}
        </TypeNotification>
      </TypeLinkContainer>
    </NotificationsMenuItem>
  );
};

export default NotificationsMenuItemType;
