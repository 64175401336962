import { ACCESS_MODULES } from './permissions';

export * from './rfisubjects';

export const ANALYST_NOTE_MODULE = ACCESS_MODULES.MODULE_CUSTOMREPORT;

export const TRIAL = 'FLAG_TRIAL';

export const LOW_QUOINS = 2;

export const MERCURY_DOCUMENT_TITLE = (title: string) => `Mercury | ${title}`;
