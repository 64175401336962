import { useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FieldValues, useForm } from 'react-hook-form';

// Components
import Button from '../button/Button';
import Message from '../message/Message';
import { richTextCss } from '../markdown/Markdown';

// Hooks
import { useMessage } from '@/hooks/useMessage';

// Types
import type { FormProps, FormValuesType, GeneralErrorFunc, SuccessMessageFunc } from '@/types/forms';
import type { PlainObject } from '@/types/general';
import Linkv2 from '../link/Linkv2';

const FormStyled = styled.form<{ $layout: string; $margin: number }>`
 @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    ${(props) => props.$layout === 'small' && 'width: 60%'};
  }

  > *:not(:first-child),
  fieldset > *:not(:first-child),
  div + &  {
    margin-top: ${(props) => props.$margin}px;
  }

  fieldset > style + *:not(:first-child) {
    margin-top: 0;
  }

  &:not(:first-child) {
    margin-top: 25px;
  }

  .mantine-Popover-dropdown & {
    button {
      width: 100%;
    }
  }
`;

export const FormContent = styled.div`
  ${richTextCss}
`;

const FormButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.S}) {
    flex-direction: row;
  }
`;

const Form = <TFormValues extends FieldValues = FieldValues>({
  options = {},
  layout = 'regular',
  margin = 25,
  onSubmit,
  children,
  name,
  submitButton,
  backLink,
  extraButton,
  fields,
  focusOn,
}: FormProps<TFormValues>) => {
  const methods = useForm<FormValuesType<TFormValues>>(options);
  const navigate = useNavigate();
  const { createMessage, removeMessage, message, messageConfig } = useMessage();

  const buildErrorMessage = (errorMessage: string, details: PlainObject<string>) => `
      <p>${errorMessage}</p>

      <ul>
      ${Object.entries(details).map(([key, val]) => `<li><b>${fields && fields[key]}:</b> ${val}</li>`).join()}
      </ul>
    `;

  const setSuccess: SuccessMessageFunc = (successMessage, reset = true, config?) => {
    createMessage(successMessage, { type: 'success' }, config);

    if (reset) methods.reset();
  };

  const setGeneralError: GeneralErrorFunc = (error) => {
    const isString = typeof error === 'string';
    let errorMessage = isString ? error : error.message;

    if (!isString) {
      if (error.details && fields) {
        const details = error.details || {};
        errorMessage = buildErrorMessage(error.message, details);

        Object.entries(details).forEach(([key, val]: any) => {
          methods.setError(key, { type: 'custom', message: val });
        });
      }
    }

    createMessage(
      errorMessage,
      { type: 'error' },
      { closeButton: true, customHtml: !isString && typeof error.details !== 'undefined' },
    );
  };

  const onSubmitMiddleWare = async (event: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return methods.handleSubmit(async (data) => {
      onSubmit(data, setGeneralError, setSuccess, methods.reset);
    })(event);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (focusOn) {
      methods.setFocus(focusOn);
    }
  }, [focusOn, methods.setFocus]);

  return (
    <>
      <Message message={message} removeMessage={removeMessage} {...messageConfig} />

      <FormStyled $margin={margin} onSubmit={onSubmitMiddleWare} name={name} $layout={layout}>
        {children(methods)}

        <FormButtons>
          {submitButton && (
            <Button
              type="submit"
              {...submitButton}
            >
              {submitButton.text}
            </Button>
          )}

          {extraButton && (
            <Button
              type="button"
              {...extraButton}
              onClick={() => extraButton.onClick?.(setGeneralError, methods.getValues())}
            >
              {extraButton.text}
            </Button>
          )}

          {backLink && (<Linkv2 as="button" type="button" onClick={navigateBack}>Back</Linkv2>)}
        </FormButtons>
      </FormStyled>
    </>
  );
};

export default Form;
