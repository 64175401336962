import Markdown from '@/components/content/markdown/Markdown';
import IssueIDLinkv2 from '../../issueIdLink/IssueIdLink';
import Title from '@/components/content/title/Title';

import type { Tracker } from '@/constants/mercuryPages';
import type { Template } from '../types';

import classes from './Results.module.css';

interface ResultProps {
  record: any;
  tracker?: Tracker;
  template?: Template;
}

const Result = ({ record, tracker, template: Template }: ResultProps) => (
  <div className={classes.resultWrapper} data-testid="search-result">
    <Title as="h3">
      <IssueIDLinkv2
        value={record.id}
        name={`${record.id} | ${record.name}`}
        tracker={tracker}
        useParent
      />
    </Title>

    {record.description && (
      <Markdown
        text={record.description}
        disallowedElements={['table', 'img', 'section']}
        textProps={{
          lineClamp: 2,
          mt: 15,
          classNames: { root: classes.descriptionText },
        }}
      />
    )}

    {Template && <Template data={record} />}
  </div>
);

export default Result;
