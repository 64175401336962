import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import Issue from '../components/IssueFooter';

import type { ResultsConfig } from '../types';
import CatalogFooter from '../components/CatalogFooter';
import Sources from '../components/Sources';
import SignalResult from '../components/SignalResult';

/**
 * Add a filter to the URLSearchParams if a value is present
 */
const filterIf = (
  filters: Record<string, any>,
  builderObj: Record<string, (v: string) => string>,
) => Object.entries(builderObj).reduce((accu, curr) => {
  const [key, format] = curr;

  if (filters[key]) {
    const value = Array.isArray(filters[key]) ? filters[key].join(' ') : filters[key];

    accu.push(format(value));
  }

  return accu;
}, [] as Array<string>);

const issues: Array<ResultsConfig> = [
  {
    template: Issue,
    endpoint: 'metis/issue/finished_intel/',
    title: 'Finished Intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tracker: mercuryTrackers[MercuryTrackersIds.finished],
    tab: 'finishedintel',
  },
  {
    template: Issue,
    endpoint: 'metis/issue/raw_intel/',
    tracker: mercuryTrackers[MercuryTrackersIds.raw],
    title: 'Raw Intel',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tab: 'rawintel',
  },
  {
    template: Issue,
    endpoint: 'metis/issue/incidents/',
    tracker: mercuryTrackers[MercuryTrackersIds.alerts],
    title: 'Incidents',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `published_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'incidents',
  },
  {
    template: Issue,
    endpoint: 'metis/issue/takedowns/',
    tracker: mercuryTrackers[MercuryTrackersIds.takedowns],
    title: 'Takedowns',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'takedowns',
  },
  {
    template: Issue,
    endpoint: 'metis/issue/rfis/',
    tracker: mercuryTrackers[MercuryTrackersIds.rfi],
    title: 'RFIs',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'rfis',
  },
  {
    endpoint: 'v0/signals',
    resultLimit: 10000,
    customResult: SignalResult,
    title: 'Signals',
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `@timestamp:${v}`,
      'industries.id': (v) => `industries.id:${v}`,
      'locations.id': (v) => `locations.id:${v}`,
    }),
    tab: 'signals',
  },
];

const catalogs: Array<ResultsConfig> = [
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/threat_actors/',
    title: 'Threat Actors',
    tracker: mercuryTrackers[MercuryTrackersIds.threatactors],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `locations.id:${v} OR raw_intel:{locations.id:${v}}`,
    }),
    tab: 'threatactors',
  },
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/malware_kits/',
    title: 'Malware Kits',
    tracker: mercuryTrackers[MercuryTrackersIds.malwarekits],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'malwarekits',
  },
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/providers/',
    title: 'Providers',
    tracker: mercuryTrackers[MercuryTrackersIds.providers],
    filterBuilder: (filters: Record<string, any>) => [
      ...filterIf(filters, {
        timeframe: (v) => `updated_at:${v}`,
        'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
        'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
      }),
      'description:*',
    ],
    tab: 'providers',
  },
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/attcks/',
    title: 'ATT&CKS',
    tracker: mercuryTrackers[MercuryTrackersIds.attck],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'attcks',
  },
  {
    template: Sources,
    endpoint: 'metis/catalog/sources/',
    title: 'Sources',
    tracker: mercuryTrackers[MercuryTrackersIds.sources],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'sources',
  },
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/vulnerabilities/',
    title: 'Vulnerabilities',
    tracker: mercuryTrackers[MercuryTrackersIds.vulnerabilitiesCat],
    filterBuilder: (filters: Record<string, any>) => filterIf(filters, {
      timeframe: (v) => `updated_at:${v}`,
      'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
      'locations.id': (v) => `raw_intel:{locations.id:${v}}`,
    }),
    tab: 'vulnerabilities',
  },
  {
    template: CatalogFooter,
    endpoint: 'metis/catalog/personas/',
    title: 'Personas',
    tracker: mercuryTrackers[MercuryTrackersIds.personas],
    filterBuilder: (filters: Record<string, any>) => [
      ...filterIf(filters, {
        timeframe: (v) => `updated_at:${v}`,
        'industries.id': (v) => `raw_intel:{industries.id:${v}}`,
        'locations.id': (v) => `locations.id:${v} OR raw_intel:{locations.id:${v}}`,
      }),
      'description:*',
    ],
    tab: 'personas',
  },
];

export const results = [
  ...issues,
  ...catalogs,
];
